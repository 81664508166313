import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { CalculateDiscountedPricePipe, OrderByPipe, RadiusButtonComponent } from '.';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

// import { BookingFormGroupComponent } from './components/booking-form-group/bookingFormGroup.component';
// import { BookingFormComponent } from './components/booking-form/booking-form.component';
// import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
// import { NavButtonComponent } from './components/nav-button/nav-button.component';
import { PhoneFieldComponent } from './components/phone-field/phone-field.component';
// import { PrimaryContactFormComponent } from './components/primary-contact-form/primary-contact-form.component';
import { PurchaseTicketFormGroupComponent } from './components/purchase-ticket-form-group/purchase-ticket-form-group.component';
import { QuantitySelectorComponent } from './components/quantity-selector/quantity-selector.component';
import { SelectPaymentMethodFormComponent } from './components/select-payment-method-form/select-payment-method-form.component';
import { SpeakersDynamicDialogComponent } from './components/speakers-dynamic-dialog/speakers-dynamic-dialog.component';
import { SubscribeDialogComponent } from './components/subscribe-dialog/subscribe-dialog.component';
// import { SuccessfulApiCallComponent } from './components/successful-api-call/successful-api-call.component';
// import { TermsFormComponent } from './components/terms-form/terms-form.component';
// import { FooterComponent } from './footer/footer.component';
// import { PrivacyPolicyComponent } from './footer/privacy-policy/privacy-policy.component';
// import { TermsAndConditionsComponent } from './footer/terms-and-conditions/terms-and-conditions.component';
import { HeaderLandingComponent } from './header-landing/header-landing.component';
import { HeaderComponent } from './header/header.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PageheaderComponent } from './pageheader/pageheader.component';
import { BillingComponent, BookingFormComponent,  FooterComponent,  MusicPlayerModule, NavButtonComponent, PrimaryContactFormComponent, SelectComponent,  SuccessfulApiCallComponent,  TermsFormComponent } from '@i-con/shared/ui';
import { ModalButtonComponent } from './components/modal-button/modal-button.component';
import { LoginFormComponent } from '@i-con/shared/ui';
import { FanSitesScheduleComponent } from './components/fan-sites-schedule/fan-sites-schedule.component';

@NgModule({
  declarations: [
    HeaderComponent,
    // FooterComponent,
    MainLayoutComponent,
    PageheaderComponent,
    CalculateDiscountedPricePipe,
    OrderByPipe,
    RadiusButtonComponent,
    QuantitySelectorComponent,
    // TermsAndConditionsComponent,
    // PrivacyPolicyComponent,
    // NavButtonComponent,
    NotFoundComponent,
    PhoneFieldComponent,
    // PrimaryContactFormComponent,
    SpeakersDynamicDialogComponent,
    HeaderLandingComponent,
    SubscribeDialogComponent,
    // BookingFormComponent,
    // BillingComponent,
    // BookingFormGroupComponent,
    SelectPaymentMethodFormComponent,
    PurchaseTicketFormGroupComponent,
    // TermsFormComponent,
    // SuccessfulApiCallComponent,
    // LoadingSpinnerComponent,
    ModalButtonComponent,
    FanSitesScheduleComponent
    
  ],
  imports: [
    CommonModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDialogModule,
    RouterModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxIntlTelInputModule,
    MatIconModule,
    MatTooltipModule,
    MusicPlayerModule,
    MatProgressSpinnerModule,
    TermsFormComponent,
    BookingFormComponent,
    BillingComponent,
    SelectComponent,
    FooterComponent,
    NavButtonComponent,
    PrimaryContactFormComponent,
    SuccessfulApiCallComponent
  ],
  exports: [
    MainLayoutComponent,
    PageheaderComponent,
    MatTabsModule,
    CalculateDiscountedPricePipe,
    OrderByPipe,
    RadiusButtonComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDialogModule,
    // NavButtonComponent,
    NotFoundComponent,
    RouterModule,
    MatDatepickerModule,
    MatNativeDateModule,
    // PrimaryContactFormComponent,
    MatTooltipModule,
    MatIconModule,
    HeaderComponent,
    // FooterComponent,
    // BookingFormComponent,
    // BookingFormGroupComponent,
    PurchaseTicketFormGroupComponent,
    SelectPaymentMethodFormComponent,
    // SuccessfulApiCallComponent,
    // LoadingSpinnerComponent,
    ModalButtonComponent,
    FanSitesScheduleComponent,
    SuccessfulApiCallComponent
  ],
})
export class SharedModule {}
